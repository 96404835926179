<template>
<div class="outer-wrapper">
    <div class="auth reset-password">

		<div class="inner-wrapper">
			<div class="heading-section">
				<h1 class="heading3">Forgot your password</h1>
			</div>

			<div class="caption text">We will send you a reset link. Please verify your email address.</div>

			<form @submit.prevent="handleSubmit">
				<div class="form-group auth">
					<label for="email">Email address</label>
					<input type="text" v-model="loginUser.email" name="email" class="form-control" :class="{ 'is-invalid': submitted && errors.has('email') }" />
					<div v-if="submitted && errors.has('email')" class="invalid-feedback">{{ errors.get('email')[0] }}</div>
				</div>
				<Loader :loading="loading"/>
				<div v-if="!loading" class="form-group btn-container">
					<button class="button btn-primary" >Send</button>
				</div>
			</form>
		</div>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import Loader from '@/components/Loader'

export default {
    data () {
        return {
			submitted: true,
			errors: new Map(),
			loading: false,
        }
    },
	components: {
		Loader
	},
    computed: {
        ...mapState(
			'account', ['status', 'loginUser']),
		...mapState({
				alert: state => state.alert,
			}),
	},
    methods: {
        ...mapActions('account', ['resetPassword', 'logout']),
		...mapActions({
			alertError: "alert/error",
			alertSuccess: "alert/success",
		}),
		checkForm() {
			this.errors = new Map();
		
			if (!this.errors.size > 0) {
				return true;
			}
			return false;
		},
		async handleSubmit (e) {
            if(this.checkForm()) {
				if (this.loginUser.email) {
					this.loading = true;
					this.resetPassword({ email: this.loginUser.email })
						.then((success) => {
							this.loading = false;
							if(success) {
								this.alertSuccess("Reset email sent");
								router.push({ name: 'Reset Sent'})
							}
						})
						.catch(error => {
							this.$rollbar.error(error);
							this.loading = false;
							this.alertError(error);
						});
				}
			}
			e.preventDefault();
        }
	},
	created () {
		this.$emit('update:topLayoutSettings', {
			left: {
				type: 'back',
				route: { name: 'Login Password', query: { redirect: this.$route.query.redirect } },
			},
			right: {
				type: 'none',
			},
		});

        // reset login status
		this.logout();
    },
};
</script>

<style scoped>
	.reset-password .title-text {
		padding-bottom: 0;
	}
	.reset-password .text {
		text-align: center;
		margin-bottom: 60px;
		text-align: center;
	}
</style>